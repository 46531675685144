import Paper from '@material-ui/core/Paper'
import React from 'react'
import TestCodeBox from './TestCodeBox'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import AceEditor from 'react-ace'

import "ace-builds/webpack-resolver"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/theme-tomorrow"

const styles = theme => ({
  root: {
    display: 'flex',
    flex:1,
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2)
  }
})

//  this.refs.aceEditor.editor.gotoLine(lineNumber)

const Editor = ({classes, code, description, error, name, onCodeChange, onTestCode, state}) => (
  <div className={classes.root}>
    <Typography paragraph variant='h5'>
      {name}
    </Typography>
    <Typography align='justify' paragraph>
      {description}
    </Typography>
    <Paper square variant='outlined' style={{flex: 1}}>
      <AceEditor width='auto' height='100%'
        fontSize={14}
        mode='javascript'
        onChange={onCodeChange}
        theme='tomorrow'
        value={code}
      />
    </Paper>
    <TestCodeBox error={error} onTestCode={onTestCode} state={state}/>
  </div>
)

export default withStyles(styles)(Editor)
