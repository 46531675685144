import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import StateIcon from './StateIcon'

const TaskSelector = ({onSelectedIndexChange, selectedIndex, solutions, tasks}) => (
  <List component='nav'>
    {tasks.map((task, index) => (
      <ListItem button key={index}
        onClick={() => onSelectedIndexChange(index)}
        selected={index === selectedIndex}>
        <ListItemAvatar>
          <Avatar>
            {index + 1}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={task.name} />
        <ListItemSecondaryAction>
          <StateIcon state={solutions[index].state}/>
         </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
)

export default TaskSelector
