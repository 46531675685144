const tasks = [
  {
    description: 'Schreibe die Funktion summe(summand1, summand2), die die Summe zweier Zahlen zurückgibt.',
    functionName: 'summe',
    name: 'Summe zweier Zahlen',
    tests: [
      {
        input: [1, 2],
        expected: 3
      },
      {
        input: [3, 4],
        expected: 7
      }
    ]
  },
  {
    description: 'Schreibe die Funktion ggt(zahl1, zahl2), die den größten gemeinsamen Teiler zweier Zahlen zurückgibt.',
    functionName: 'ggt',
    name: 'Größter gemeinsamer Teiler',
    tests: [
      {
        input: [8, 16],
        expected: 8
      },
      {
        input: [15, 25],
        expected: 5
      }
    ]
  },
  {
    description: 'Schreibe die Funktion istSchaltjahr(jahr). Sie gibt true zurück, wenn jahr ein Schaltjahr ' +
      'ist. Sie gibt false zurück, wenn jahr kein Schaltjahr ist.',
    functionName: 'istSchaltjahr',
    name: 'Schaltjahr',
    tests: [
      {
        input: 1980,
        expected: true
      },
      {
        input: 1981,
        expected: false
      }
    ]
  },
  {
    description: 'Schreibe die Funktion fibonacciFolge(n), die die ersten n Fibonacci Zahlen in einem Array zurückgibt.',
    functionName: 'fibonacciFolge',
    name: 'Fibonacci Folge',
    tests: [
      {
        input: 4,
        expected: [0, 1, 1, 2, 3]
      },
      {
        input: 7,
        expected: [0, 1, 1, 2, 3, 5, 8, 13]
      }
    ]
  }
]

export default tasks
