import Fab from '@material-ui/core/Fab'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import React from 'react'
import StateIcon from './StateIcon'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import prettier from 'prettier'

const styles = theme => ({
  result: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  stateIcon: {
    marginLeft: theme.spacing(2),
  },
  testCodeIcon: {
    marginRight: theme.spacing(1),
  }
})

const getErrorMessage = ({colno, message, lineno}) => {
  if (lineno) {
    return `${message} in line ${lineno} at position ${colno}`
  }
  return message
}

const TestCodeBox = ({classes, error, onTestCode, state}) => (
  <div className={classes.root}>
    <Fab
      aria-label='Test'
      color="primary"
      disabled={state === 'TESTING'}
      onClick={onTestCode}
      size='medium'
      variant='extended'
    >
      <PlayCircleFilledIcon className={classes.testCodeIcon}/>
      Test
    </Fab>
    <div className={classes.result}>
      {state === 'SUCCEEDED' &&
        <Typography variant='h6'>
          Succeeded
        </Typography>
      }
      {error &&
        <Typography color='error' variant='h6'>
          {getErrorMessage(error)}
        </Typography>
      }
      {(state === 'SUCCEEDED' || error) &&
        <div className={classes.stateIcon}>
          <StateIcon state={state}/>
        </div>
      }
    </div>
  </div>
)

export default withStyles(styles)(TestCodeBox)
