import './App.css'
import Editor from './Editor'
import logo from './logo.svg'
import Worksheet from './Worksheet'

const App = () => (
  <div className="App">
    <Worksheet/>
  </div>
)

export default App;


/*
<header className="App-header">
  <img src={logo} className="App-logo" alt="logo" />
  <p>
    Edit <code>src/App.js</code> and save to reload.
  </p>
  <a
    className="App-link"
    href="https://reactjs.org"
    target="_blank"
    rel="noopener noreferrer"
  >
    Learn React
  </a>

  <Editor/>

</header>
*/
