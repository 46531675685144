const createRunSingleTestCode = ({expected, functionName, input}) => {
  return `else if (${functionName}(${input}) !== ${expected}) {
  postMessage('Incorrect Result: Different result expected');
}`
}

const createWorkerCode = ({code, functionName, tests}) => {
  const runAllTestsCode = tests
    .map(({expected, input}) => createRunSingleTestCode({expected, functionName, input}))
    .join('\n')
  return `${code}
if (typeof ${functionName} === 'undefined') {
  postMessage('Function Undefined: Function ${functionName} is not defined');
}
else if (typeof ${functionName} !== 'function') {
  postMessage('Function Undefined: ${functionName} is not a function');
}
${runAllTestsCode}
else {
  postMessage('')
}`
}

const state = {
  errors: [],
  isRunning: false
}

const testCode = ({code, functionName, tests, onFinished}) => {
  let isRunning = true;
  const workerCode = createWorkerCode({code, functionName, tests})
  const blob = new Blob([workerCode], {type: 'application/javascript'});
  const worker = new Worker(URL.createObjectURL(blob));
  worker.addEventListener('message', function(event) {
    isRunning = false;
    if (event.data === '') {
      onFinished({isSucceeded: true})
    }
    else {
      onFinished({error: {message: event.data}, isSucceeded: false})
    }
  });
  worker.addEventListener('error', function(error) {
    isRunning = false
    onFinished({error, isSucceeded: false})
  });
  setTimeout(() => {
    if (!isRunning) {
      return
    }
    isRunning = false
    worker.terminate()
    onFinished({error: {message: 'Timeout Error: Code is running too long'}, isSucceeded: false})
  }, 2000)
}

export default testCode
