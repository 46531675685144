import AutorenewIcon from '@material-ui/icons/Autorenew'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/Error'
import FiberNewOutlinedIcon from '@material-ui/icons/FiberNewOutlined'
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  succeededIcon: {
    color: green[500]
  }
})

const StateIcon = ({classes, state}) => {
  switch (state) {
    case 'FAILED': return (<ErrorIcon color='error' />)
    case 'NOT_STARTED': return (<FiberNewOutlinedIcon color='primary'/>)
    case 'SUCCEEDED': return (<CheckCircleIcon className={classes.succeededIcon} />)
    case 'TESTING': return (<AutorenewIcon color='inherit' />)
    default: return (<EditIcon color='primary'/>)
  }
}

export default withStyles(styles)(StateIcon)
