import AppBar from './AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Editor from './Editor'
import Paper from '@material-ui/core/Paper'
import React, { useState } from 'react'
import tasks from './tasks'
import TaskSelector from './TaskSelector'
import testCode from './testCode'
import { withStyles } from '@material-ui/core/styles'

const styles =theme => ({
  content: {
    alignItems: 'stretch',
    display: 'flex',
    flex: 1
  },
  root: {
    alignContent: 'stretch',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    padding: theme.spacing(4)
  },
  taskSelector: {
    width: 300
  }
})

const Worksheet = ({classes}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [solutions, setSolutions] = useState(Array.from({length: tasks.length}, () => ({code: '', state: 'NOT_STARTED'})))
  return (
    <div className={classes.root}>
      <Paper elevation={3} style={{width: '100%', maxWidth: '1200px', display: 'flex', flexShrink: 1, flexGrow: 1, flexDirection: 'column', alignContent: 'stretch'}}>
        <AppBar solutions={solutions}/>
        <Divider/>
        <div className={classes.content}>
          <div className={classes.taskSelector}>
            <TaskSelector
              onSelectedIndexChange={setSelectedIndex}
              solutions={solutions}
              selectedIndex={selectedIndex}
              tasks={tasks}/>
          </div>
          <Divider orientation='vertical' flexItem/>
          <Editor
            code={solutions[selectedIndex].code}
            description={tasks[selectedIndex].description}
            error={solutions[selectedIndex].error}
            name={tasks[selectedIndex].name}
            onCodeChange={(code) => setSolutions([
              ...solutions.slice(0, selectedIndex),
              {code},
              ...solutions.slice(selectedIndex + 1)])}
            onTestCode={() => {
              setSolutions([
                ...solutions.slice(0, selectedIndex),
                {code: solutions[selectedIndex].code, state: 'TESTING'},
                ...solutions.slice(selectedIndex + 1)])
              testCode({
                code: solutions[selectedIndex].code,
                functionName: tasks[selectedIndex].functionName,
                tests: tasks[selectedIndex].tests,
                onFinished: ({error, isSucceeded}) => {
                  setSolutions([
                    ...solutions.slice(0, selectedIndex),
                    {code: solutions[selectedIndex].code, error, state: isSucceeded ? 'SUCCEEDED': 'FAILED'},
                    ...solutions.slice(selectedIndex + 1)])
                }
              })
            }}
            state={solutions[selectedIndex].state}
          />
        </div>
      </Paper>
    </div>
  )
}


export default withStyles(styles)(Worksheet)
