import AppBar from '@material-ui/core/AppBar'
import React from 'react'
import SchoolIcon from '@material-ui/icons/School'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  title: {
    flexGrow: 1
  }
})

const AppBar2 = ({classes, solutions}) => (
  <AppBar position='static'>
  <Toolbar variant='dense'>
    <IconButton aria-label='About' color='inherit' edge='start'>
      <SchoolIcon />
    </IconButton>
    <Typography className={classes.title} color='inherit' variant='h6'>
      JavaScript Tester der Assistenten
    </Typography>
    <Typography color='inherit' variant='h6'>
      {`${solutions.filter(solution => solution.state === 'SUCCEEDED').length} of ${solutions.length}`}
    </Typography>
  </Toolbar>
</AppBar>
)

export default withStyles(styles)(AppBar2)
